
import { defineComponent, inject, ref, onMounted, Ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { useStore } from "../store";
import moment from "moment";
import { MessageOutlined, ArrowLeftOutlined } from "@ant-design/icons-vue";
import AddMessage from "../components/AddMessage.vue";
import { Conversation, Conversations, ConversationStatus } from "../../types";
import { useRouter } from "vue-router";

export const ConversationComponent = defineComponent({
  props: {
    id: {
      type: Object as PropType<Conversations["conversationId"]>,
      required: true,
    },
  },
  components: {
    ArrowLeftOutlined,
    MessageOutlined,
    AddMessage,
  },
  setup(props) {
    const store = useStore();
    const http = inject("http") as AxiosStatic;
    const { t, d } = useI18n();
    const router = useRouter();

    const conversation: Ref<Conversation> = ref({
      status: "ANSWERED" as ConversationStatus,
      visitDate: "",
      messages: [],
    });

    const addMessageVisible: Ref<boolean> = ref(false);
    const loadingconversation: Ref<boolean> = ref(false);

    const getconversation = async () => {
      loadingconversation.value = true;

      try {
        const conversationRes: Conversation = await http
          .get(`v1/conversations/${props.id}`)
          .then((res) => res.data);

        conversation.value = conversationRes;
      } catch {
        notification.open({
          message: t("ERROR.FE.4956"),
          class: "error",
        });
      } finally {
        loadingconversation.value = false;
      }
    };
    const afterAddMessageModalSuccess = async () => {
      await getconversation();
    };

    const goBack = () => {
      router.push({ path: "/conversations" });
    };

    onMounted(async () => {
      getconversation();
    });

    return {
      conversation,
      store,
      addMessageVisible,
      d,
      afterAddMessageModalSuccess,
      loadingconversation,
      goBack,
      moment,
      t,
    };
  },
});
export default ConversationComponent;
